import React, { useContext, useState } from 'react'
// Context
import UserContext from 'context/UserContext'
// Firebase
import firebase from 'auth/Auth'
import fb from 'firebase/app'
// reactstrap components
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Alert
} from 'reactstrap'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'

const styles = {
  boxLabel: {
    minWidth: 124
  }
}

export default function Notifications () {
  const context = useContext(UserContext)
  const [errorMsg, setErrorMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)
  const [sendUrl, setSendUrl] = useState(false)

  return (
    <div>
      {errorMsg && (
        <div style={{
          position: 'absolute',
          top: 20,
          zIndex: '999',
          width: '100%'
        }}
        >
          <Alert className='mt-5 container' color='danger' fade>
            <span className='alert-inner--icon'>
              <i className='fas fa-exclamation-triangle' />
            </span>{' '}
            <span className='alert-inner--text'>
              <strong>Error: </strong> {errorMsg}
            </span>
            <button onClick={() => setErrorMsg(null)} type='button' className='close' aria-label='Close'>
              <span className='text-white' aria-hidden='true'>×</span>
            </button>
          </Alert>
        </div>
      )}
      {successMsg && (
        <div style={{
          position: 'absolute',
          top: 20,
          zIndex: '999',
          width: '100%'
        }}
        >
          <Alert className='mt-5 container' color='success' fade>
            <span className='alert-inner--icon'>
              <i className='fas fa-check' />
            </span>{' '}
            <span className='alert-inner--text'>
              {successMsg}
            </span>
            <button onClick={() => setSuccessMsg(null)} type='button' className='close' aria-label='Close'>
              <span className='text-white' aria-hidden='true'>×</span>
            </button>
          </Alert>
        </div>
      )}
      <Container fluid>
        <Formik
          validateOnMount
          initialValues={{ segment: { value: 'All', label: 'Todos' } }}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true)

            const enterpriseSendPush = firebase.functions().httpsCallable('enterpriseSendPush')
            enterpriseSendPush({
              ...values,
              segment: values.segment.value,
              ...(context.company.oneSignalAppId && { app_id: context.company.oneSignalAppId })
            }).then(result => {
              setSuccessMsg('Notificación enviada correctamente.')
              setSubmitting(false)
              resetForm({ segment: { value: 'All', label: 'Todos' } })
              const increment = fb.firestore.FieldValue.increment(1)
              const currentMonth = (new Date()).getMonth() + 1
              const currentYear = (new Date()).getFullYear()
              firebase.firestore()
                .collection('customers')
                .doc(context.user.uid)
                .update({ ['notifications.' + currentYear + '.' + currentMonth]: increment })
            }).catch(err => {
              setSubmitting(false)
              console.log(err)
              setErrorMsg(err)
            })
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .required(),
            subtitle: Yup.string()
              .required(),
            message: Yup.string()
              .required(),
            segment: Yup.object()
              .required(),
            url: Yup.string().url()
          })}
        >
          {({
            values,
            isValid,
            isSubmitting,
            setSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            dirty
          }) => (
            <Container className='pt-8'>
              <Card className='p-4'>
                <CardTitle
                  tag='h5'
                  className='text-uppercase text-muted mb-0'
                >
                        Envío de Notificaciones Push
                </CardTitle>
                <CardBody>
                  <Row>
                    <Col className='text-center' md='12' lg='5' xl='5'>
                      <div style={{ position: 'relative' }}>
                        <img
                          alt=''
                          style={{
                            position: 'absolute',
                            top: '33%',
                            left: '14%',
                            width: '20px',
                            borderRadius: '4px'
                          }}
                          src={context.company?.iconApp}
                        />
                        <small
                          className='text-muted'
                          style={{
                            position: 'absolute',
                            top: '33%',
                            left: '0',
                            fontSize: '14px',
                            marginLeft: '90px',
                            borderRadius: '4px',
                            width: '100%',
                            fontWeight: '600',
                            textAlign: 'left'
                          }}
                        >
                          {context.company?.appName}
                        </small>
                        <small
                          className='text-muted'
                          style={{
                            position: 'absolute',
                            top: '42%',
                            left: '0',
                            fontSize: '14px',
                            marginLeft: '90px',
                            borderRadius: '4px',
                            width: '64%',
                            textAlign: 'left',
                            fontWeight: '400'
                          }}
                        >
                          <b>{values.title}</b>
                          <br />
                          <b>{values.subtitle}</b>
                          <br />
                          {values.message}
                        </small>
                        <img alt='' width='100%' src={require('assets/img/push_preview.png')} />
                      </div>
                    </Col>
                    <Col className='mt-4' md='12' lg='7' xl='7'>
                      <InputGroup>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Título</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='title'
                          value={values.title || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength='40'
                          placeholder='Título...'
                          type='text'
                        />
                      </InputGroup>
                      <InputGroup className='mt-4'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Subtítulo</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='subtitle'
                          value={values.subtitle || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength='40'
                          placeholder='Subtítulo...'
                          type='text'
                        />
                      </InputGroup>
                      <InputGroup className='mt-4'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Mensaje</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='message'
                          value={values.message || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength='120'
                          placeholder='Mensaje...'
                          type='text'
                        />
                      </InputGroup>
                      <Row className='mb-3 pl-3 mt-4 pr-4'>
                        <label className='custom-toggle mr-1'>
                          <input
                            onClick={() => setSendUrl(!sendUrl)}
                            checked={sendUrl} type='checkbox'
                          />
                          <span
                            className='custom-toggle-slider rounded-circle'
                          />
                        </label>
                        <span className={(sendUrl ? 'text-primary' : 'text-light') + ' ml-3'}>
                                Abrir URL al hacer click en la notificación
                        </span>
                      </Row>
                      {sendUrl && (
                        <InputGroup className='mb-3'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText style={styles.boxLabel}>URL</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name='url'
                            value={values.url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder='https://url.com...'
                            type='url'
                          />
                        </InputGroup>
                      )}
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='d-flex justify-content-end'>
                  <Button
                    className='btn-icon'
                    disabled={!isValid || isSubmitting || !dirty}
                    type='submit'
                    color='primary'
                    onClick={handleSubmit}
                  >
                    <span className='btn-inner--icon'>
                      <i className='ni ni-send' />
                    </span>
                    <span className='btn-inner--text'>{isSubmitting ? 'Enviando...' : 'Enviar'}</span>
                  </Button>
                </CardFooter>
              </Card>
            </Container>
          )}
        </Formik>
      </Container>
    </div>
  )
}
