import React from 'react'
import ReactDOM from 'react-dom'
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
// bootstrap rtl for rtl support page
import 'assets/vendor/bootstrap-rtl/bootstrap-rtl.scss'
// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
// Context
import ThemeContext from 'context/ThemeContext'
// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css'
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css'
import 'assets/vendor/select2/dist/css/select2.min.css'
import 'assets/vendor/quill/dist/quill.core.css'
import 'assets/vendor/nucleo/css/nucleo.css'
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0'
// Helmet
import { Helmet } from 'react-helmet'
// Layous
import AdminLayout from 'layouts/Admin.js'
import AuthLayout from 'layouts/Auth.js'
// Config
import { THEMES } from 'config/themes'
const parseDomain = require('parse-domain')

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const domainInfo = parseDomain(window.location.href)
let companyTheme = 'default'
if (domainInfo && domainInfo.subdomain) {
  companyTheme = domainInfo.subdomain
} else {
  const urlParam = getParameterByName('company', window.location.search)
  if (urlParam && THEMES[urlParam]) {
    companyTheme = urlParam
  }
}

ReactDOM.render(
  <ThemeContext.Provider
    value={{
      theme: THEMES[companyTheme]
    }}
  >
    <Helmet>
      <title>{THEMES[companyTheme].title || 'Overlapp Enterprise'} | Dashboard</title>
    </Helmet>
    <BrowserRouter>
      <Switch>
        <Route path='/admin' render={props => <AdminLayout {...props} />} />
        <Route path='/auth' render={props => <AuthLayout {...props} />} />
        <Redirect from='*' to='/admin' />
      </Switch>
    </BrowserRouter>
  </ThemeContext.Provider>,
  document.getElementById('root')
)
