import React from 'react'
// Firebase
import firebase from 'auth/Auth'
// react library for routing
import { Route, Switch, Redirect } from 'react-router-dom'
// core components
import AuthNavbar from 'components/Navbars/AuthNavbar.js'
import AuthFooter from 'components/Footers/AuthFooter.js'
// Routes
import routes from 'routes.js'

class Auth extends React.Component {
  state = {
    subscriptionModal: true,
    loading: true
  }

  componentDidMount () {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    this.refs.mainContent.scrollTop = 0
    // document.body.classList.add('bg-white')
  }

  componentWillUnmount () {
    // document.body.classList.remove('bg-white')
  }

  componentWillMount () {
    firebase.auth().onAuthStateChanged(async (user) => {
      this.setState({ loading: false })
      if (user) {
        this.props.history.push('/admin')
      }
    })
  }

  componentDidUpdate (e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainContent.scrollTop = 0
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  };

  render () {
    if (this.state.loading) {
      return (
        <div
          className='main-content'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
          ref='mainContent'
        >
          <img className='animate-logo' width='100' alt='logo' src={require('assets/img/brand/logo-circle.png')} />
        </div>
      )
    } else {
      return (
        <>
          <div className='main-content' ref='mainContent'>
            <AuthNavbar />
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from='*' to='/auth/login' />
            </Switch>
          </div>
          <AuthFooter />
        </>
      )
    }
  }
}

export default Auth
