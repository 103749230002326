import React from 'react'

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap'

class Login extends React.Component {
  render () {
    return (
      <>
        <footer className='py-5' id='footer-main'>
          <Container>
            <Row className='align-items-center justify-content-xl-between'>
              <Col xl='3'>
                <div className='copyright text-center text-xl-left text-muted'>
                  © {new Date().getFullYear()}{' '}
                  <a
                    className='font-weight-bold ml-1'
                    href='https://estudiocactus.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Estudio Cactus ®
                  </a>
                </div>
              </Col>
              <Col xl='9'>
                <Nav className='nav-footer justify-content-center justify-content-xl-end'>
                  <NavItem>
                    <NavLink
                      href='https://overlapp.io'
                      target='_blank'
                    >
                      Overlapp
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href='https://overlapp.io/terms'
                      target='_blank'
                    >
                      Términos y Condiciones
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href='https://overlapp.io/privacy-policy'
                      target='_blank'
                    >
                      Política de privacidad
                    </NavLink>
                  </NavItem>
                  <NavItem className='ml-4'>
                    <NavLink
                      href='https://www.instagram.com/overlapp.io/'
                      target='_blank'
                    >
                      <i className='fab fa-instagram' />
                    </NavLink>
                  </NavItem>
                  <NavItem className='ml-4'>
                    <NavLink
                      href='https://www.youtube.com/channel/UCMSZe9x6jCc3nVMHpvEeJ4A'
                      target='_blank'
                    >
                      <i className='fab fa-youtube' />
                    </NavLink>
                  </NavItem>
                  <NavItem className='ml-4'>
                    <NavLink
                      href='https://www.linkedin.com/company/overlapp-estudio-cactus/'
                      target='_blank'
                    >
                      <i className='fab fa-linkedin' />
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )
  }
}

export default Login
