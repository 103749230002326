import React, { useState } from 'react'
// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from 'reactstrap'
// core components
import AuthHeader from 'components/Headers/AuthHeader.js'
// Firebase
import firebase from 'auth/Auth.js'
import { Formik } from 'formik'
// Formik
import * as Yup from 'yup'

const Login = (props) => {
  const [errorMsg, setErrorMsg] = useState(null)

  return (
    <>
      <AuthHeader />
      {errorMsg && (
        <div style={{
          position: 'absolute',
          zIndex: '999',
          width: '100%'
        }}
        >
          <Alert className='mt-5 container' color='danger' fade>
            <span className='alert-inner--icon'>
              <i className='fas fa-exclamation-triangle' />
            </span>{' '}
            <span className='alert-inner--text'>
              <strong>Error: </strong> {errorMsg}
            </span>
            <button onClick={() => setErrorMsg(null)} type='button' className='close' aria-label='Close'>
              <span className='text-white' aria-hidden='true'>×</span>
            </button>
          </Alert>
        </div>
      )}
      <Container className='mt-8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Formik
              initialValues={{ email: '', password: '', confirmPassword: '' }}
              onSubmit={async (values, { setSubmitting }) => {
                const { email, password } = values
                try {
                  await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                } catch (error) {
                  console.log('Login error', error)
                  setErrorMsg(error.message)
                }
                setSubmitting(false)
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required('Email es obligatorio'),
                password: Yup.string()
                  .required('Password es obligatorio')
                  .min(6, 'Password demasiado corto')
              })}
            >
              {formikProps => {
                const {
                  values,
                  dirty,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = formikProps
                return (
                  <form onSubmit={handleSubmit}>
                    <div className='text-center pb-4'>
                      <span className='h1'>
                        Inicia sesión con tu cuenta
                      </span>
                      <p className='text-gray'>Introduce tu correo electrónico y password</p>
                    </div>
                    <FormGroup>
                      <InputGroup className='input-group-alternative'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ni ni-email-83' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='email'
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Email'
                          type='email'
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className='input-group-alternative'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ni ni-lock-circle-open' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='password'
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Password'
                          type='password'
                        />
                      </InputGroup>
                    </FormGroup>
                    <span
                      style={{ cursor: 'pointer' }}
                      href='signup'
                      className='text-light text-xs float-right mb-4'
                      onClick={() => props.history.push('/auth/forgot-password')}
                    >
                      ¿Password olvidado?
                    </span>
                    <div className='text-center'>
                      <Button block disabled={!dirty || isSubmitting || !isValid} className='my-4' color='primary' type='submit'>
                        INICIAR SESIÓN
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Login
