import React, { useContext, useState } from 'react'
// // Firebase
import firebase from 'auth/Auth'
// Context
import UserContext from 'context/UserContext'
// reactstrap components
import {
  Navbar,
  Nav,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  NavbarToggler,
  Collapse,
  DropdownMenu
} from 'reactstrap'
// Themes
import { THEMES } from 'config/themes'

const AdminNavbar = props => {
  const context = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <Navbar
        className='navbar-top navbar-expand navbar-dark'
        light expand='md'
        style={{
          backgroundColor: THEMES[context.company.id].primaryColor
        }}
      >
        <Container fluid>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <i className='fas fa-user mr-2' />
                  <span className='nav-link-inner--text'>{context.user.email}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className='noti-title' header tag='div'>
                    <h6 className='text-overflow m-0'>Ajustes</h6>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => firebase.auth().signOut()}
                  >
                    <i className='ni ni-button-power' />
                    <span>Cerrar sesión</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default AdminNavbar
