import React from 'react'
// react library for routing
import { Link } from 'react-router-dom'
// Context
import ThemeContext from 'context/ThemeContext'
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  // NavItem,
  // NavLink,
  Nav,
  Container,
  Row,
  Col
  // Button,
  // UncontrolledTooltip
} from 'reactstrap'

class AdminNavbar extends React.Component {
  static contextType = ThemeContext
  render () {
    return (
      <>
        <Navbar
          className='navbar-horizontal navbar-main navbar-dark navbar-transparent'
          expand='lg'
          id='navbar-main'
          style={{
            backgroundColor: this.context.theme.primaryColor
          }}
        >
          <Container>
            <NavbarBrand to='/' tag={Link}>
              <img
                alt='...'
                src={this.context.theme.logo}
              />
            </NavbarBrand>
            <button
              aria-controls='navbar-collapse'
              aria-expanded={false}
              aria-label='Toggle navigation'
              className='navbar-toggler'
              data-target='#navbar-collapse'
              data-toggle='collapse'
              id='navbar-collapse'
              type='button'
            >
              <span className='navbar-toggler-icon' />
            </button>
            <UncontrolledCollapse
              className='navbar-custom-collapse'
              navbar
              toggler='#navbar-collapse'
            >
              <div className='navbar-collapse-header'>
                <Row>
                  <Col className='collapse-brand' xs='6'>
                    <Link to='/admin/dashboard'>
                      <img
                        alt='...'
                        src={require('assets/img/brand/logo-green.svg')}
                      />
                    </Link>
                  </Col>
                  <Col className='collapse-close' xs='6'>
                    <button
                      aria-controls='navbar-collapse'
                      aria-expanded={false}
                      aria-label='Toggle navigation'
                      className='navbar-toggler'
                      data-target='#navbar-collapse'
                      data-toggle='collapse'
                      id='navbar-collapse'
                      type='button'
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className='align-items-lg-center ml-lg-auto' navbar>
                {/* <NavItem>
                  <NavLink
                    className='nav-link-icon'
                    href='https://www.facebook.com/creativetim?ref=creative-tim'
                    id='tooltip601201423'
                    target='_blank'
                  >
                    <i className='fab fa-facebook-square' />
                    <span className='nav-link-inner--text d-lg-none'>
                      Facebook
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target='tooltip601201423'>
                    Facebook
                  </UncontrolledTooltip>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className='nav-link-icon'
                    href='https://www.instagram.com/estudiocactus'
                    id='tooltip871243015'
                    target='_blank'
                  >
                    <i className='fab fa-instagram' />
                    <span className='nav-link-inner--text d-lg-none'>
                      Instagram
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target='tooltip871243015'>
                    Síguenos en Instagram
                  </UncontrolledTooltip>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className='nav-link-icon'
                    href='https://twitter.com/creativetim?ref=creative-tim'
                    id='tooltip366258619'
                    target='_blank'
                  >
                    <i className='fab fa-twitter-square' />
                    <span className='nav-link-inner--text d-lg-none'>
                      Twitter
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target='tooltip366258619'>
                    Twitter
                  </UncontrolledTooltip>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink to='/auth/pricing' tag={Link}>
                    <i className='fas fa-shopping-cart mr-2' />
                    <span className='nav-link-inner--text'>Planes de suscripción</span>
                  </NavLink>
                </NavItem> */}
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default AdminNavbar
