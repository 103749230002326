import Login from 'views/Login.js'
import ForgotPassword from 'views/ForgotPassword.js'
import Notifications from 'views/Notifications.js'
import Materials from 'views/Materials.js'
import Stats from 'views/Stats.js'

const routes = [
  {
    path: '/login',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    miniName: 'FP',
    component: ForgotPassword,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/stats',
    name: 'App Stats',
    icon: 'ni ni-chart-bar-32 text-primary',
    component: Stats,
    layout: '/admin'
  },
  {
    path: '/references',
    name: 'Referencias',
    icon: 'ni ni-ungroup text-primary',
    component: Materials,
    layout: '/admin'
  },
  {
    path: '/notifications',
    name: 'Notificaciones',
    icon: 'ni ni-send text-primary',
    component: Notifications,
    layout: '/admin'
  }
]

export default routes
