import React from 'react'
// Firebase
import firebase from 'auth/Auth'
// react library for routing
import { Route, Switch, Redirect } from 'react-router-dom'
// core components
import Loader from 'components/Loader/Loader'
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import AdminFooter from 'components/Footers/AdminFooter.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
// Helmet
import { Helmet } from 'react-helmet'
// Context
import UserContext from 'context/UserContext'
// Routes
import routes from 'routes.js'
// Config
import { THEMES } from 'config/themes'

class Admin extends React.Component {
  state = {
    sidenavOpen: true,
    user: null,
    materials: 0,
    showModalSubscription: false,
    loading: true,
    subscription: null,
    companyInfo: null,
    stripe: null,
    setLoading: (newState) => { this.setState({ loading: newState }) }
  }

  checkCompanyInfo = customerInfo => {
    if (customerInfo.exists && customerInfo.data().company) {
      firebase.firestore()
        .collection('companiesEnterprise')
        .doc(customerInfo.data().company)
        .onSnapshot(snapshotCompany => {
          if (snapshotCompany.data()) {
            firebase.firestore()
              .collection('companiesEnterprise')
              .doc(snapshotCompany.id)
              .collection('materials')
              .onSnapshot(snapshotMaterials => {
                if (snapshotMaterials.docs.length > 0) {
                  this.setState({
                    materials: snapshotMaterials.docs.map(material => {
                      return {
                        ...material.data(),
                        materialId: material.id
                      }
                    })
                  })
                } else {
                  this.setState({ materials: [] })
                }
              })
            this.setState({
              companyInfo: {
                ...snapshotCompany.data(),
                id: snapshotCompany.id
              }
            })
          } else {
            this.setState({ companyInfo: null })
          }
        })
    } else {
      this.setState({ companyInfo: null })
    }
  }

  checkSubscription = uid => {
    this.setState({
      loading: false,
      subscription: true,
      stripe: {
        subscription: null,
        plan: {
          name: 'enterprise',
          materials: 300
        }
      }
    })
  }

  componentWillMount () {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        firebase.firestore()
          .collection('customersEnterprise')
          .doc(user.uid)
          .onSnapshot(snapshot => {
            this.setState({ user: { ...user, data: snapshot.data() } })

            // Check Company Info
            this.checkCompanyInfo(snapshot)

            // Check Subscription
            this.checkSubscription(user.uid)
          })
      } else {
        this.setState({ user: null, subscription: null, companyInfo: null })
        this.props.history.push('/auth/login')
      }
    })
  }

  componentDidUpdate (e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainContent.scrollTop = 0
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name
      }
    }
    return 'Brand'
  }

  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned')
      document.body.classList.add('g-sidenav-hidden')
    } else {
      document.body.classList.add('g-sidenav-pinned')
      document.body.classList.remove('g-sidenav-hidden')
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    })
  }

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      'admin/alternative-dashboard'
    ) === -1
      ? 'dark'
      : 'light'
  }

  render () {
    if (this.state.loading || !this.state.user || !this.state.companyInfo) {
      return (
        <div
          className='main-content'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
          ref='mainContent'
        >
          <Loader />
        </div>
      )
    } else {
      return (
        <UserContext.Provider
          value={{
            user: this.state.user,
            company: this.state.companyInfo,
            materials: this.state.materials,
            stripe: this.state.stripe,
            setLoading: this.state.setLoading
          }}
        >
          <Helmet>
            <title>{this.state.companyInfo.name} | Dashboard</title>
          </Helmet>
          <>
            <Sidebar
              {...this.props}
              routes={routes}
              toggleSidenav={this.toggleSidenav}
              sidenavOpen={this.state.sidenavOpen}
              logo={{
                innerLink: '/',
                imgSrc: THEMES[this.state.companyInfo.id].logo,
                imgAlt: '...'
              }}
            />
            <div
              className='main-content'
              ref='mainContent'
              onClick={this.closeSidenav}
            >
              <AdminNavbar
                {...this.props}
                theme={this.getNavbarTheme()}
                toggleSidenav={this.toggleSidenav}
                sidenavOpen={this.state.sidenavOpen}
                brandText={this.getBrandText(this.props.location.pathname)}
              />
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from='*' to='/admin/references' />
              </Switch>
              <AdminFooter />
            </div>
          </>
          {this.state.sidenavOpen ? (
            <div className='backdrop d-xl-none' onClick={this.toggleSidenav} />
          ) : null}
        </UserContext.Provider>
      )
    }
  }
}

export default Admin
