export const THEMES = {
  neolith: {
    logo:
      'https://firebasestorage.googleapis.com/v0/b/overlapp-estudiocactus.appspot.com/o/companiesEnterprise%2Fneolith%2Fneolith.svg?alt=media&token=0ab5858d-654b-4086-81f8-6cc04cfce861',
    primaryColor: '#FCD800',
    title: 'Neolith'
  },
  cupastone: {
    logo:
      'https://firebasestorage.googleapis.com/v0/b/overlapp-estudiocactus.appspot.com/o/companiesEnterprise%2Fcupastone%2Flogo-header-es.png?alt=media&token=44c9dc50-8482-403e-998a-ec3a93ff9dde',
    primaryColor: '#6C1D45',
    title: 'Cupastone'
  },
  default: {
    logo: require('assets/img/brand/logo-white.svg'),
    primaryColor: '#B6D774'
  }
}
