import React, { useContext, useState, useEffect } from 'react'
// Context
import UserContext from 'context/UserContext'
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  CardHeader,
  CardTitle
} from 'reactstrap'

const Stats = () => {
  const context = useContext(UserContext)
  const [references, setReferences] = useState({ numberOfReferences: [], topUsageReferences: [], usages: 0 })
  const [users, setUsers] = useState({ android: 0, apple: 0 })

  const numberWithCommas = x => {
    var parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return parts.join('.')
  }

  useEffect(() => {
    const { materials, company: { appAndroidUsers, appAppleUsers } } = context
    if (typeof materials === 'object') {
      const topUsageReferences = materials.filter((material) => typeof material.usages === 'number').sort((a, b) => b.usages - a.usages)

      const usages = materials.reduce((accumulator, currentMaterial) => {
        if (typeof currentMaterial.usages === 'number') {
          return accumulator + currentMaterial.usages
        }

        return accumulator
      }, 0)

      setReferences({ numberOfReferences: materials.length, topUsageReferences, usages })
      setUsers({ android: appAndroidUsers, apple: appAppleUsers })
    }
  }, [context])

  return (
    <div className='header bg-primary pt-4 pb-6'>
      <Container fluid>
        <div className='header-body'>
          <Row className='justify-content-md-between'>
            <Col md='6' xl>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <div className='col'>
                      <CardTitle
                        tag='h5'
                        className='text-uppercase text-muted mb-0'
                      >
                          Usuarios Android
                      </CardTitle>
                      <span className='h1 font-weight-bold mb-0'>{users.android}</span>
                    </div>
                    <Col className='col-auto'>
                      <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                        <i className='ni ni-circle-08' />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md='6' xl>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <div className='col'>
                      <CardTitle
                        tag='h5'
                        className='text-uppercase text-muted mb-0'
                      >
                          Usuarios Apple
                      </CardTitle>
                      <span className='h1 font-weight-bold mb-0'>{users.apple}</span>
                    </div>
                    <Col className='col-auto'>
                      <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                        <i className='ni ni-circle-08' />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md='6' xl>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <div className='col'>
                      <CardTitle
                        tag='h5'
                        className='text-uppercase text-muted mb-0'
                      >
                          Referencias
                      </CardTitle>
                      <span className='h1 font-weight-bold mb-0'>{references.numberOfReferences}</span>
                    </div>
                    <Col className='col-auto'>
                      <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                        <i className='ni ni-app' />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md='6' xl>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <div className='col'>
                      <CardTitle
                        tag='h5'
                        className='text-uppercase text-muted mb-0'
                      >
                          Usos
                      </CardTitle>
                      <span className='h1 font-weight-bold mb-0'>{references.usages}</span>
                    </div>
                    <Col className='col-auto'>
                      <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                        <i className='ni ni-active-40' />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col xl='12' md='12'>
              <Card>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h6 className='text-primary text-uppercase ls-1 mb-1'>
                          Estadísticas
                      </h6>
                      <p className='font-weight-normal mb-0'>
                          Referencias de <b>{context.company.name}</b> más
                          usadas
                      </p>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className='fixed-height-stats p-0'>
                  <Table
                    className='align-items-center table-flush'
                    responsive
                  >
                    <thead className='thead-light'>
                      <tr>
                        <th scope='col'>Referencia</th>
                        <th scope='col'>Colección</th>
                        <th scope='col'>Usos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {references.topUsageReferences.map((material, index) => {
                        return (
                          <tr key={index}>
                            <th scope='row'>{material.name}</th>
                            <td>{material.collection}</td>
                            <td className='text-primary'>
                              <b>{numberWithCommas(material.usages)}</b>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
export default Stats
